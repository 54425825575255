import { type ColumnDef } from '@tanstack/react-table';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { DataTableColumnHeader } from '@/components/data-tables/data-table-column-header';
import { Link, useNavigate } from 'react-router-dom';
import { ConsignmentStatus } from '@/constants/enums';
import { formatDate } from '@/lib/utils';
import Consignment from '@/models/consignment.ts';
import AccountTooltip from '@/pages/Administration/Tooltip/AccountTooltip.tsx';

export const getColumns = (): ColumnDef<Consignment>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-0.5"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-0.5"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'consignmentId',
    header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
    cell: ({ row }) => <div className="w-3">{row.getValue('consignmentId')}</div>,
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: 'user.nickname',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Requester Name" />,
    cell: ({ row }) => {
      return (
        <AccountTooltip account={row.original.user}>
          <Link to={`/admin/account/${row.original.user.accountId}`}>{row.original.user.nickname}</Link>
        </AccountTooltip>
      );
    },
    enableSorting: true,
  },
  {
    accessorKey: 'staff.nickname',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Assigned Staff" />,
    cell: ({ row }) => {
      return row.original.staff ? (
        <AccountTooltip account={row.original.staff}>
          <Link to={`/admin/account/${row.original.staff.accountId}`}>{row.original.staff.nickname}</Link>
        </AccountTooltip>
      ) : (
        <></>
      );
    },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => {
      switch (row.original.status) {
        case ConsignmentStatus.WAITING_STAFF:
          return (
            <Badge variant="default" className="bg-yellow-500 w-[150px] text-center flex justify-center items-center">
              Waiting for Staff
            </Badge>
          );
        case ConsignmentStatus.IN_INITIAL_EVALUATION:
          return (
            <Badge variant="default" className="bg-blue-500 w-[150px] text-center flex justify-center items-center">
              In Initial Evaluation
            </Badge>
          );
        case ConsignmentStatus.IN_FINAL_EVALUATION:
          return (
            <Badge variant="default" className="bg-indigo-500 w-[150px] text-center flex justify-center items-center">
              In Final Evaluation
            </Badge>
          );
        case ConsignmentStatus.SENDING:
          return (
            <Badge variant="default" className="bg-purple-500 w-[150px] text-center flex justify-center items-center">
              Sending
            </Badge>
          );
        case ConsignmentStatus.TERMINATED:
          return (
            <Badge variant="default" className="bg-red-500 w-[150px] text-center flex justify-center items-center">
              Terminated
            </Badge>
          );
        case ConsignmentStatus.WAITING_SELLER:
          return (
            <Badge variant="default" className="bg-pink-400 w-[150px] text-center flex justify-center items-center">
              Waiting seller
            </Badge>
          );
        case ConsignmentStatus.TO_ITEM:
          return (
            <Badge variant="default" className="bg-cyan-400 w-[150px] text-center flex justify-center items-center">
              To Item
            </Badge>
          );
        case ConsignmentStatus.FINISHED:
          return (
            <Badge variant="default" className="bg-green-400 w-[150px] text-center flex justify-center items-center">
              Finished
            </Badge>
          );
        default:
          return <Badge variant="destructive">Unknown Status</Badge>;
      }
    },

    //   {row.getValue("status") == ConsignmentStatus.ACTIVE ?
    //     <Badge variant="default" className="bg-green-500">{ConsignmentStatus[row.status]}</Badge> :
    //     <Badge variant="destructive">{ConsignmentStatus[row.status]}</Badge>}
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'createDate',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
    cell: ({ row }) => formatDate(new Date(row.getValue('createDate'))),
  },
  {
    accessorKey: 'preferContact',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Prefer Contact" />,
    cell: ({ row }) => <div className="w-20">{row.getValue('preferContact')}</div>,
    enableSorting: true,
    enableHiding: false,
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const nav = useNavigate();

      const handleDetailClick = (consignmentId: number) => {
        nav(`/admin/consignments/${consignmentId}`);
      };

      return (
        <>
          <Button
            size="sm"
            onClick={() => {
              handleDetailClick(row.original.consignmentId);
            }}
          >
            Detail
          </Button>
        </>
      );
    },
  },
];

export default getColumns;
