import { SERVER_DOMAIN_URL } from '@/constants/domain';
import { getCookie, getBearerToken } from '@/utils/cookies';
import axios from '@/config/axiosConfig.ts';

const URL = `${SERVER_DOMAIN_URL}/api/consignmentDetails/`;

export const fetchConsigntmentDetailByConsignmentId = async (id: number) => {
  return await axios
    .get(URL + id, {
      headers: {
        'Content-Type': 'application/json',

        Authorization: getBearerToken(),
      },
    })
    .then((res) => {
      console.log(res.data);
      return res;
    }) // return the data here
    .catch((err) => {
      console.log(err);
      throw err; // make sure to throw the error so it can be caught by the query
    });
};
export const fetchConsigntmentDetailByConsignmentDetailId = async (id: number) => {
  return await axios
    .get(URL + 'detail/' + id, {
      headers: {
        'Content-Type': 'application/json',

        Authorization: getBearerToken(),
      },
    })
    .then((res) => {
      return res.data;
    }) // return the data here
    .catch((err) => {
      console.log(err);
      throw err; // make sure to throw the error so it can be caught by the query
    });
};
export const updateConsignmentDetailService = async (data: any, id: number) => {
  return await axios
    .put(URL + 'update/' + id, data, {
      headers: {
        'Content-Type': 'application/json',

        Authorization: getBearerToken(),
      },
    })
    .catch((err) => console.log(err));
};

export const createInitialEvaluation = async (data: any) => {
  return await axios.post(URL + 'createInitialEvaluation', data, {
    headers: {
      'Content-Type': 'multipart/form-data',

      Authorization: getBearerToken(),
    },
  });
};
export const createFinalEvaluation = async (data: any) => {
  return await axios.post(URL + 'createFinalEvaluation', data, {
    headers: {
      'Content-Type': 'multipart/form-data',

      Authorization: getBearerToken(),
    },
  });
};

export const exportConsignmentDetails = async () => {
  return await fetch(`${SERVER_DOMAIN_URL}/api/consignmentDetails/export`, {
    method: 'GET',
    headers: {
      Authorization: getBearerToken(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create a blob URL and create a link element to trigger the download
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = 'consignmentDetails.xlsx'; // Set the desired file name with .xls extension
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error('Error fetching Excel file:', error);
    });
};
