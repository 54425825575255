import CountDownTime from './components/countdownTimer/CountDownTime';
import Ekyc from './Ekyc';

export default function StripePayment() {
  return (
    <div>
      {/* <CheckoutForm /> */}
      {/* <Ekyc />
       */}
      <CountDownTime end={new Date('Thu Jul 18 2024 15:14:27 GMT+0000')} />
    </div>
  );
}
