export enum Roles {
  MEMBER = 'MEMBER',
  STAFF = 'STAFF',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  GUEST = 'GUEST',
} // enums.ts

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  // Add more statuses if needed (e.g., BANNED, SUSPENDED)
}

export enum AuctionSessionStatus {
  TERMINATED = 'TERMINATED',
  FINISHED = 'FINISHED',
  PROGRESSING = 'PROGRESSING',
  SCHEDULED = 'SCHEDULED',
}

export enum ConsignmentContactPreference {
  ANY = 'ANY',
  TEXT_MESSAGE = 'TEXT_MESSAGE',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export enum ConsignmentStatus {
  WAITING_STAFF = 'WAITING_STAFF',
  IN_INITIAL_EVALUATION = 'IN_INITIAL_EVALUATION',
  SENDING = 'SENDING',
  IN_FINAL_EVALUATION = 'IN_FINAL_EVALUATION',
  WAITING_SELLER = 'WAITING_SELLER',
  TO_ITEM = 'TO_ITEM',
  FINISHED = 'FINISHED',
  TERMINATED = 'TERMINATED',
}

export enum ConsignmentDetailType {
  MANAGER_REJECTED = 'MANAGER_REJECTED',
  MANAGER_ACCEPTED = 'MANAGER_ACCEPTED',
  FINAL_EVALUATION = 'FINAL_EVALUATION',
  INITIAL_EVALUATION = 'INITIAL_EVALUATION',
}

export enum ItemStatus {
  UNSOLD = 'UNSOLD',
  SOLD = 'SOLD',
  IN_AUCTION = 'IN_AUCTION',
  QUEUE = 'QUEUE',
  VALUATING = 'VALUATING',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum PaymentType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  AUCTION_DEPOSIT = 'AUCTION_DEPOSIT',
  AUCTION_ORDER = 'AUCTION_ORDER',
  CONSIGNMENT_REWARD = 'CONSIGNMENT_REWARD',
}

export enum RoleName {
  MEMBER = 'MEMBER',
  STAFF = 'STAFF',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
}
