'use client';

import { DownloadIcon } from '@radix-ui/react-icons';
import { type Table } from '@tanstack/react-table';

import { exportTableToCSV } from '@/lib/export';
import { Button } from '@/components/ui/button';
import { CalendarClock, ListFilter, SearchIcon } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { formatDateToISO, getEnumValue, parseDate, parseIntOrUndefined } from '@/lib/utils.ts';
import { ConsignmentStatus } from '@/constants/enums.tsx';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Form, FormField } from '@/components/ui/form.tsx';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { DateTimePicker } from '@/components/time-picker/date-time-picker.tsx';
import { Input } from '@/components/ui/input.tsx';
import Consignment from '@/models/consignment.ts';
import { ConsignmentsSecretCodeFinder } from '@/pages/Administration/consignments/consignments-data-table/consignments-secret-code-finder.tsx';

interface TasksTableToolbarActionsProps {
  table: Table<Consignment>;
}

type FormData = {
  from?: Date;
  to?: Date;
  useFrom: boolean;
  useTo: boolean;
  search?: string;
  customer?: number;
};

export function ConsignmentsTableToolbarActions({ table }: TasksTableToolbarActionsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = getEnumValue(ConsignmentStatus, searchParams.get('status')) as ConsignmentStatus;
  const form = useForm<FormData>({
    defaultValues: {
      useFrom: parseDate(searchParams.get('from')) !== undefined,
      useTo: parseDate(searchParams.get('to')) !== undefined,
      from: parseDate(searchParams.get('from')),
      to: parseDate(searchParams.get('to')),
      customer: parseIntOrUndefined(searchParams.get('customer')),
      search: searchParams.get('search'),
    },
  });
  const formValues = form.watch();

  function setParam(key: string, value: any | undefined | null) {
    if (String(value).length === 0) value = undefined;
    if (
      (!searchParams.has(key) && (value === undefined || value === null)) ||
      (searchParams.has(key) && value === searchParams.get(key))
    ) {
      return;
    }
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    setSearchParams(searchParams, { replace: true });
  }

  useEffect(() => {
    const handleFieldChange = () => {
      setParam('from', formValues.useFrom && formValues.from ? formatDateToISO(formValues.from) : undefined);
      setParam('to', formValues.useTo && formValues.to ? formatDateToISO(formValues.to) : undefined);
      setParam('search', formValues.search);
    };
    handleFieldChange();
  }, [formValues]);

  return (
    <div className="flex justify-between grow">
      <div className="flex gap-2">
        <Form {...form}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm">
                <ListFilter className="mr-2 size-4" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">Status</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuLabel>Status</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem checked={status === undefined} onClick={() => setParam('status', undefined)}>
                All
              </DropdownMenuCheckboxItem>
              {Object.keys(ConsignmentStatus).map((s) => (
                <DropdownMenuCheckboxItem checked={status === s} onClick={() => setParam('status', s)}>
                  {s}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline" size="sm">
                <CalendarClock className="mr-2 size-4" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">Date</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <div className="space-y-2">
                <h4 className="font-medium leading-none">Date Range</h4>
              </div>
              <div className="flex flex-col gap-2 mt-3">
                <div className="flex justify-center items-center gap-2">
                  <FormField
                    control={form.control}
                    name="useFrom"
                    render={({ field }) => <Checkbox checked={field.value} onCheckedChange={field.onChange} />}
                  />
                  <DateTimePicker {...form.register('from')} placeholder="from" />
                </div>
                <div className="flex justify-center items-center gap-2">
                  <FormField
                    control={form.control}
                    name="useTo"
                    render={({ field }) => <Checkbox checked={field.value} onCheckedChange={field.onChange} />}
                  />
                  <DateTimePicker {...form.register('to')} placeholder="to" />
                </div>
              </div>
            </PopoverContent>
          </Popover>
          <FormField
            control={form.control}
            name="search"
            render={({ field }) => (
              <div className="w-full relative">
                <Input {...field} className="px-8 h-9 focus-visible:[box-shadow:none]" />
                <SearchIcon className="absolute left-1.5 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500 peer-focus:text-gray-900" />
              </div>
            )}
          />
        </Form>
      </div>
      <div className="flex gap-2">
        <ConsignmentsSecretCodeFinder />
        <Button
          variant="outline"
          size="sm"
          onClick={() =>
            exportTableToCSV(table, {
              filename: 'tasks',
              excludeColumns: ['select', 'actions'],
            })
          }
        >
          <DownloadIcon className="mr-2 size-4" aria-hidden="true" />
          Export
        </Button>
      </div>
    </div>
  );
}
