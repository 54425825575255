import PropTypes from 'prop-types';

// material-ui
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project import
import MainCard from '../../pages/Dashboard/MainCard';

// assets
// import RiseOutlined from "@ant-design/icons/RiseOutlined";
// import FallOutlined from "@ant-design/icons/FallOutlined";

const iconSX = {
  fontSize: '0.75rem',
  color: 'inherit',
  marginLeft: 0,
  marginRight: 0,
};

export default function AnalyticEcommerce({ color = 'primary', title, count, percentage, isLoss, extra }) {
  return (
    <div className="bg-backgoundcolor p-5">
      <MainCard contentSX={{ p: 2.25 }}>
        <Stack spacing={0.5}>
          <Typography variant="h7" color="text.secondary">
            {title}
          </Typography>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="h4" color="inherit">
                {count}
              </Typography>
            </Grid>
            {percentage}
          </Grid>
        </Stack>
        <Box sx={{ pt: 2.25 }}>
          <Typography variant="caption" color="text.secondary">
            {/* You made an extra{" "} */}
            <Typography variant="caption" sx={{ color: `${color || 'primary'}.main` }}>
              {extra}
            </Typography>{' '}
            {/* this year */}
          </Typography>
        </Box>
      </MainCard>
    </div>
  );
}

AnalyticEcommerce.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.string,
};
