import { SERVER_DOMAIN_URL } from '@/constants/domain';
import { getCookie, getBearerToken, removeCookie } from '@/utils/cookies';
import axios from '@/config/axiosConfig.ts';

interface GetBlogsSchema {
  page: number;
  size: number;
  sort?: string;
  order?: 'asc' | 'desc';
  status?: string;
  categoryId?: number;
  search: string;
}

class BlogService {
  private static readonly BASE_URL = `${SERVER_DOMAIN_URL}/api/blogs`;

  public static getBlogs = async (input: GetBlogsSchema) => {
    const { page, size, sort, search, categoryId } = input;
    let response;

    let params: Record<string, any> = {
      page: page && page - 1, // Spring Boot uses 0-based page index
      size: size ? size : 10,
      sort: sort ? sort : 'postId,desc',
      categoryId: categoryId ?? '',
      search,
    };

    response = await axios.get(`${this.BASE_URL}/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getBearerToken(),
      },
      params: params,
    });
    return response;
  };

  public static getAllBlogs(page?: number, size?: number) {
    let params = {
      page: page || 0,
      size: size || 50,
    };
    return axios.get(`${this.BASE_URL}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    });
  }

  public static searchBlog(keyword: string, page: number, size: number) {
    let params = {
      page: page,
      size: size,
    };
    return axios.get(`${this.BASE_URL}/search?keyword=${keyword}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    });
  }

  public static getBlogByCategory(categoryId: number, page: number, size: number) {
    let params = {
      page: page || 0,
      size: size || 50,
    };
    return axios.get(`${this.BASE_URL}/category?categoryId=${categoryId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: params,
    });
  }

  public static getBlogById(id: number) {
    return axios.get(`${this.BASE_URL}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public static createBlog(blog: any) {
    return axios.post(this.BASE_URL + '/', blog, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getBearerToken(),
      },
    });
  }

  public static updateBlog(id: number, blog: any) {
    return axios.put(`${this.BASE_URL}/${id}`, blog, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: getBearerToken(),
      },
    });
  }

  public static deleteBlog(id: number) {
    return axios.delete(`${this.BASE_URL}/${id}`);
  }
}

export default BlogService;
