/**
 * Retrieves the value of a cookie by its name.
 * @param cookieName - The name of the cookie to retrieve.
 * @returns The value of the cookie, or an empty string if the cookie is not found.
 */
export function getCookie(cookieName: string) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

/**
 * Sets a cookie with the specified name, value, and expiration time.
 * @param cookieName - The name of the cookie.
 * @param cookieValue - The value to be stored in the cookie.
 * @param cookieExp - The expiration time of the cookie in minutes.
 */
export function setCookie(cookieName: string, cookieValue: string, cookieExp: number) {
  const d = new Date();
  d.setTime(d.getTime() + cookieExp * 60 * 1000); // Adjusted to minutes
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

/**
 * Removes a cookie by setting its expiration date to a past date.
 * @param cookieName - The name of the cookie to remove.
 */
export const removeCookie = (cookieName: string) => {
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export const getBearerToken = () => {
  const user = getCookie('user');
  if (user && user.length > 0) {
    const token = JSON.parse(user).accessToken;
    if (token) {
      return 'Bearer ' + token;
    }
  }
  return undefined;
};
