/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/z3rGZhSw0Us
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

/** Add fonts into your Next.js project:

import { DM_Sans } from 'next/font/google'

dm_sans({
  subsets: ['latin'],
  display: 'swap',
})

To read more about using these font, please visit the Next.js documentation:
- App Directory: https://nextjs.org/docs/app/building-your-application/optimizing/fonts
- Pages Directory: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
**/
import { Button } from '@/components/ui/button';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ErrorPage() {
  const handleClick = () => {
    window.history.back();
  };
  return (
    <div className="flex h-[100dvh] flex-col items-center justify-center gap-8 bg-gray-100 dark:bg-gray-900">
      <div className="space-y-4 text-center">
        <h1 className="text-6xl font-bold tracking-tighter text-gray-900 dark:text-gray-50">404</h1>
        <p className="text-lg text-gray-500 dark:text-gray-400">Oops, the page you are looking for does not exist.</p>
      </div>
      <Button onClick={handleClick} variant="default">
        Go Back
      </Button>
    </div>
  );
}
