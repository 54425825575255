import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Bell,
  PanelLeft,
  AreaChartIcon,
  FolderClosed,
  User2,
  Menu,
  Newspaper,
  ShoppingBag,
  CircleDollarSign,
  Package,
} from 'lucide-react';
import { createContext, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';

import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { useLocation } from 'react-router-dom';
import ProfileDropdownMenu from '@/components/NavBar/ProfileDropdownMenu.tsx';
import logo from '@/assets/icon.png';
import { useAuth } from '@/AuthProvider';
import { Roles } from '@/constants/enums';
import { checkScrollPositionAndReset } from '../layoutUtils/layout-utils';
import AccountRoleBadge from '@/components/AccountRoleBadge.tsx';
import * as React from 'react';

export const ConsignmentsContext = createContext([]);

export default function Administration() {
  const location = useLocation();
  const auth = useAuth();
  const nav = useNavigate();
  const [consignments] = useState([]);
  const [arrayPath, setArrayPath] = useState(['']);
  const breadcrumbs = [<BreadcrumbItem key={1}></BreadcrumbItem>];

  const loadBreadcrumbs = () => {
    let tempPath = '/admin';
    // console.log(arrayPath);
    for (let i = 2; i < arrayPath.length; i++) {
      tempPath = tempPath + '/' + arrayPath[i];
      // console.log(tempPath);
      breadcrumbs.push(
        <BreadcrumbItem key={i}>
          <BreadcrumbLink asChild>
            <Link to={tempPath}>{arrayPath[i].toLocaleUpperCase()}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
      );
      if (i < arrayPath.length - 1) {
        breadcrumbs.push(<BreadcrumbSeparator />);
      }
    }
    return <BreadcrumbList>{breadcrumbs}</BreadcrumbList>;
  };
  useEffect(() => {
    // Call the function on component mount and path change
    checkScrollPositionAndReset();
    if (!auth.user) {
      nav('/auth/login');
    }
    // Optional: If you want to check the scroll position continuously or on a specific event, you can add more logic here
  }, [location.pathname]);

  useEffect(() => {
    // console.log(location);
    let tempArrayPath = location.pathname.split('/');
    setArrayPath(tempArrayPath);
    console.log(breadcrumbs);
  }, [location]);

  const isAdmin = React.useCallback(() => {
    return auth.user.role === Roles.ADMIN;
  }, [auth.user.role]);

  const isManager = React.useCallback(() => {
    return auth.user.role === Roles.MANAGER;
  }, [auth.user.role]);

  const isStaff = React.useCallback(() => {
    return auth.user.role === Roles.STAFF;
  }, [auth.user.role]);

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr] bg-background text-foreground">
      <div className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6 ">
            <Link to="#" className="flex items-center gap-2 font-semibold">
              <div className="flex items-center justify-center gap-2">
                <img src={logo} className="w-8 h-8" alt="logo" />
                <span className="font-semibold text-lg text-orange-700 text-center">Biddify</span>
              </div>
            </Link>
            <AccountRoleBadge className="ml-auto" role={auth.user.role} />
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
              {isAdmin() && (
                <Link
                  to="dashboard"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                >
                  <Menu />
                  Dashboard
                </Link>
              )}

              {isAdmin() && (
                <Link
                  to="accounts?page=1"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                >
                  <User2 />
                  Manage Accounts
                </Link>
              )}

              {isAdmin() ||
                (isManager() && (
                  <Link
                    to="customers?page=1"
                    className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                  >
                    <User2 />
                    View Customers
                  </Link>
                ))}
              {(auth.user.role === Roles.ADMIN ||
                auth.user.role === Roles.MANAGER ||
                auth.user.role === Roles.STAFF) && (
                <Link
                  to="consignments"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                >
                  <FolderClosed />
                  Manage Consignments
                </Link>
              )}
              {(isAdmin() || isManager()) && (
                <Link
                  to="auction-sessions"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                >
                  <AreaChartIcon />
                  Manage Auction Session
                </Link>
              )}
              {(isAdmin() || isManager()) && (
                <Link
                  to="jewelry"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                >
                  <ShoppingBag />
                  Manage Jewelries
                </Link>
              )}
              <Link
                to="blogs"
                className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
              >
                <Newspaper />
                Manage Blogs
              </Link>
              {(isAdmin() || isManager()) && (
                <Link
                  to="orders"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                >
                  <Package />
                  Manage Orders
                </Link>
              )}
              {(isAdmin() || isManager()) && (
                <Link
                  to="payments"
                  className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                >
                  <CircleDollarSign />
                  Manage Payments
                </Link>
              )}
            </nav>
          </div>
        </div>
      </div>
      <ConsignmentsContext.Provider value={consignments}>
        <div className="flex flex-col sm:gap-4 sm:py-4">
          <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
            <Sheet>
              <SheetTrigger asChild>
                <Button size="icon" variant="outline" className="sm:hidden">
                  <PanelLeft className="h-5 w-5" />
                  <span className="sr-only">Toggle Menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="sm:max-w-xs">
                <nav className="grid gap-6 text-lg font-medium">
                  <Link to="#" className="flex items-center gap-2 font-semibold">
                    <Avatar>
                      <AvatarImage src="src\assets\icon.png" />
                    </Avatar>
                    <span className="">BIDDIFY</span>
                  </Link>
                  {isAdmin() && (
                    <Link
                      to="dashboard"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <Menu />
                      Dashboard
                    </Link>
                  )}

                  {auth.user.role === Roles.ADMIN && (
                    <Link
                      to="accounts?page=1"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <User2 />
                      Manage Accounts
                    </Link>
                  )}

                  {auth.user.role !== Roles.ADMIN && (
                    <Link
                      to="customers?page=1"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <User2 />
                      View Customers
                    </Link>
                  )}
                  {(auth.user.role === Roles.ADMIN ||
                    auth.user.role === Roles.MANAGER ||
                    auth.user.role === Roles.STAFF) && (
                    <Link
                      to="consignments"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <FolderClosed />
                      Manage Consignments
                    </Link>
                  )}
                  {(isAdmin() || isManager()) && (
                    <Link
                      to="auction-sessions"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <AreaChartIcon />
                      Manage Auction Session
                    </Link>
                  )}
                  {(isAdmin() || isManager()) && (
                    <Link
                      to="jewelry"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <ShoppingBag />
                      Manage Jewelries
                    </Link>
                  )}
                  <Link
                    to="blogs"
                    className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                  >
                    <Newspaper />
                    Manage Blogs
                  </Link>
                  {(isAdmin() || isManager()) && (
                    <Link
                      to="orders"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <Package />
                      Manage Orders
                    </Link>
                  )}
                  {(isAdmin() || isManager()) && (
                    <Link
                      to="payments"
                      className="flex items-center gap-3 rounded-lg px-3 py-2 text-foreground transition-all hover:text-primary"
                    >
                      <CircleDollarSign />
                      Manage Payments
                    </Link>
                  )}
                </nav>
              </SheetContent>
            </Sheet>
            <Breadcrumb className="hidden md:flex">{loadBreadcrumbs()}</Breadcrumb>
            <div className="relative ml-auto flex-1 md:grow-0"></div>
            <ProfileDropdownMenu></ProfileDropdownMenu>
          </header>
          <Outlet></Outlet>
        </div>
      </ConsignmentsContext.Provider>
    </div>
  );
}
